// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productos-dispositivos-medicos-js": () => import("./../../../src/pages/productos/dispositivos-medicos.js" /* webpackChunkName: "component---src-pages-productos-dispositivos-medicos-js" */),
  "component---src-pages-productos-equipo-extra-hospitalario-js": () => import("./../../../src/pages/productos/equipo-extra-hospitalario.js" /* webpackChunkName: "component---src-pages-productos-equipo-extra-hospitalario-js" */),
  "component---src-pages-productos-equipo-medico-js": () => import("./../../../src/pages/productos/equipo-medico.js" /* webpackChunkName: "component---src-pages-productos-equipo-medico-js" */),
  "component---src-pages-productos-index-js": () => import("./../../../src/pages/productos/index.js" /* webpackChunkName: "component---src-pages-productos-index-js" */),
  "component---src-pages-productos-instrumental-js": () => import("./../../../src/pages/productos/instrumental.js" /* webpackChunkName: "component---src-pages-productos-instrumental-js" */),
  "component---src-pages-productos-laboratorio-js": () => import("./../../../src/pages/productos/laboratorio.js" /* webpackChunkName: "component---src-pages-productos-laboratorio-js" */),
  "component---src-pages-productos-proyectos-integrales-js": () => import("./../../../src/pages/productos/proyectos-integrales.js" /* webpackChunkName: "component---src-pages-productos-proyectos-integrales-js" */)
}

